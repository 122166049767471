@import 'compass';
@import 'compass/support';

@import 'setting';
@import 'mixin';
@import '_kond';

/* ======================================================================
 p_404
====================================================================== */
.p_404 {
  .l_main_visual {
    &:after {
      background-image: url('../img/404/main_bg.png');
    }

    .sprange {
      display: none;

      @media screen and (max-width:405px) {
        display: block;
      }
    }

    .sp2 {
      @media screen and (max-width:405px) {
        display: block;
        padding-left: 20px;
        font-size: 4.8rem;
      }
    }
  }


  .l_content {
    .l_main {
      >.inner {
        @include mq_pc {
          width: 1100px;
          margin: 0 auto;
        }

        @include mq_tbsp {
          padding: 0 4%;
        }

        .list {
          padding: 30px 0 30px 40px;

          @include mq_sp {
            padding: 25px 0 25px 30px;
          }
        }

        .btn {
          margin-top: 30px;

          @include mq_tbsp {
            margin-top: 20px;
          }

          .eng {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}