// ページ全体の横幅
$pc_width: 1280px;
$sp_width: 767px;

$pc_fixwidth: 1100px;


@mixin mq_sp {
  @media screen and (max-width: $sp_width) {
    @content;
  }
}

@mixin mq_tbonly {
  @media screen and (min-width: $sp_width+1px) and (max-width: $pc_width) {
    @content;
  }
}

@mixin mq_tbfix {
  @media screen and (min-width: $pc_width+1px) and (max-width: $pc_fixwidth) {
    @content;
  }
}


@mixin mq_tbsp {
  @media screen and (max-width: $pc_width) {
    @content;
  }
}

@mixin mq_tb {
  @media screen and (min-width: $sp_width +1px) {
    @content;
  }
}

@mixin mq_pc {
  @media screen and (min-width: $pc_width +1px) {
    @content;
  }
}

@mixin mq_pcl {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin mq_pcw {
  @media screen and (min-width: 2560px) {
    @content;
  }
}


//kondo original
@mixin fuwari($tm) {
  @include transition(all $tm linear);
}

@mixin fuwari_d($tm, $delay) {
  @include transition(all $tm linear $delay);
}

@mixin tb_center {
  @include translateY(-50%);
  position: absolute;
  top: 50%;
}


@mixin noto {
  font-family: $noto-font;
}

// @import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@mixin josefin {
  font-family: 'Josefin Sans', sans-serif;
}

@mixin nova {
  font-family: 'Nova Round', cursive;
}

._nova {
  @include nova;
}

@mixin rale {
  font-family: 'Raleway', sans-serif;
}

._rale {
  @include rale;
}

@mixin tange {
  font-family: 'Tangerine', cursive;
}

._tange {
  @include tange;
}

@mixin com {
  font-family: 'Cormorant', serif;
}

._com {
  @include com;
}

@mixin scheherazade {
  font-family: 'Scheherazade', serif;
}

._scheherazade {
  @include scheherazade;
}



// ._josefin {
// 	@include josefin;
// }
@mixin jura {
  font-family: 'Jura', sans-serif;
}

.jura {
  font-family: 'Jura', sans-serif;
}


@mixin yugo {
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
}

@mixin flexcenter {
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);
}




@mixin font_mp {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

@mixin font_deli {
  font-family: 'Delius Unicase', cursive;
}


@mixin boxshadow {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .1);
}

@mixin flexcenter {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);

}


@for $i from 0 through 40 {
  .u_mb#{$i*5} {
    margin-bottom: #{$i*5}px;
  }

  .u_mb#{$i*5}_pc {
    @include mq_pc {
      margin-bottom: #{$i*5}px;
    }
  }

  .u_mb#{$i*5}_sp {
    @include mq_sp {
      margin-bottom: #{$i*5}px;
    }
  }

  .u_mb#{$i*5}_tb {
    @include mq_tb {
      margin-bottom: #{$i*5}px;
    }
  }

  .u_mb#{$i*5}_tbsp {
    @include mq_tbsp {
      margin-bottom: #{$i*5}px;
    }
  }

  .u_mb#{$i*5}_tbonly {
    @include mq_tbonly {
      margin-bottom: #{$i*5}px;
    }
  }
}



//  @include gradient(top,bottom,transparent,$color_7,280px);
@mixin gradient($start, $end, $startcolor, $endcolor, $percent) {
  background: $startcolor;
  /* Old browsers */
  background: -moz-linear-gradient($start, $startcolor 0%, $startcolor $percent, $endcolor $percent, $endcolor 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient($start, $startcolor 0%, $startcolor $percent, $endcolor $percent, $endcolor 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to $end, $startcolor 0%, $startcolor $percent, $endcolor $percent, $endcolor 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$startcolor', endColorstr='$endcolor', GradientType=0);
  /* IE6-9 */
}


// 固有
@mixin listparts1 {
  .l_layout_g .box {
    @include mq_tbsp {
      padding-top: 0;
    }

    .txt_wrap {
      @include mq_sp {
        margin-right: $sidesp;
        padding-right: 20px;
      }

      .ttl {
        @include mq_pc {
          padding-left: 30px;
        }

        .innertxt:after {
          @include mq_pc {
            right: 15px;
          }
        }
      }

      .txt_inner {
        @include mq_pc {
          padding: 25px 0 0px 30px;
        }
      }
    }
  }


  .box1 {
    margin-bottom: 100px;

    @include mq_pc {
      &.js_window_in {
        padding-bottom: 50px;
      }

      padding-bottom: 100px;
      background: url(../img/service/service_bg01.png) no-repeat center 30px;
    }

    @include mq_tbsp {
      background: url(../img/service/service_bg01sp.png) no-repeat center top;
      margin-bottom: 10px;
    }
  }

  .box2 {
    img {
      margin-left: auto;
    }

    margin-bottom: 100px;

    @include mq_pc {
      &.js_window_in {
        padding-bottom: 50px;
      }

      padding-bottom: 100px;
      background: url(../img/service/service_bg02.png) no-repeat center 30px;
    }

    @include mq_tbsp {
      background: url(../img/service/service_bg02sp.png) no-repeat center top;
      margin-bottom: 10px;
    }
  }

  .box3 {
    @include mq_pc {
      @include translateY(80px);
    }

    @include mq_tbsp {
      padding-top: 0;
      margin-bottom: 10px;
    }
  }

  .box_flexpc {
    @include mq_pc {
      padding-top: 30px;
      padding-bottom: 370px;

      &.js_window_in {
        padding-bottom: 300px;
      }

    }
  }
}